import { VStack } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { IoMdNotificationsOutline } from "react-icons/io";

import { FiUsers } from "react-icons/fi";
import { BiMessage } from "react-icons/bi";
import { TbReport } from "react-icons/tb";
import { FaList, FaListUl, FaUsers } from "react-icons/fa";
import { CiImageOn, CiPlay1 } from "react-icons/ci";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdOutlinePolicy, MdPolicy } from "react-icons/md";
import { GoOrganization } from "react-icons/go";



const Sidebar = () => {
  const location = useLocation();
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  return (
    <VStack align={"start"}>
      {user.role === "superadmin" && (
        <Link
          to={"/"}
          className={
            location.pathname.split("/")[1] == "" ? "activelink" : "link"
          }
        >
          <RxDashboard size={20} />
          Dashboard
        </Link>
      )}
      {user.role === "superadmin" && (
        <Link
          to={"/claims"}
          className={
            location.pathname.split("/")[1] == "claims" ? "activelink" : "link"
          }
        >
          <TbReport size={20} />
          Claims
        </Link>
      )}
      {user.role === "superadmin" && (
        <Link
          to={"/users"}
          className={
            location.pathname.split("/")[1] == "users" ? "activelink" : "link"
          }
        >
          <FiUsers size={20} />
          Users
        </Link>
      )}

      {user.role === "superadmin" && (
        <Link
          to={"/notifications"}
          className={
            location.pathname.split("/")[1] == "notifications"
              ? "activelink"
              : "link"
          }
        >
          <IoMdNotificationsOutline size={20} />
          Notification
        </Link>
      )}
      {user.role === "superadmin" && (
        <Link
          to={"/policy-provider"}
          className={
            location.pathname.split("/")[1] === "policy-provider"
              ? "activelink"
              : "link"
          }
        >
          <GoOrganization size={20} />
          Policy Providers
        </Link>
      )}
      {user.role === "superadmin" && (
        <Link
          to={"/policies-list"}
          className={
            location.pathname.split("/")[1] === "policies-list"
              ? "activelink"
              : "link"
          }
        >
          {/* <FaListUl size={20} /> */}
          <MdOutlinePolicy size={20} />

          Policies List
        </Link>
      )}

      <Link
        to={
          user.role == "Team lead" ||
          user.role == "Team member" ||
          user?.role == "Manager"
            ? "/"
            : "/all-users-policy"
        }
        className={
          location.pathname.split("/")[1] === "all-users-policy" ||
          (user.role == "Team lead" &&
            location.pathname.split("/")[1] === "") ||
          (user.role == "Manager" && location.pathname.split("/")[1] === "") ||
          (user.role == "Team member" && location.pathname.split("/")[1] === "")
            ? "activelink"
            : "link"
        }
      >
        <MdPolicy size={20} />
        Users Policies
      </Link>
      {/* {user.role === "superadmin" && ( */}
      <Link
        to={"/messages"}
        className={
          location.pathname.split("/")[1] == "messages" ? "activelink" : "link"
        }
      >
        <BiMessage size={20} />
        Message
      </Link>
      {/* )} */}
      {user.role === "Team member" || (
        <Link
          to={"/all-users-documents"}
          className={
            location.pathname.split("/")[1] === "all-users-documents"
              ? "activelink"
              : "link"
          }
        >
          {/* <FaListUl size={20} /> */}
          <IoDocumentTextOutline size={20} />

          Users Documents
        </Link>
      )}

      {user.role === "Team member" || (
        <Link
          to={"/employee-list"}
          className={
            location.pathname.split("/")[1] === "employee-list"
              ? "activelink"
              : "link"
          }
        >
         <FaUsers size={20} />
          Employee
        </Link>
      )}

<Link
          to={"/bg-image"}
          className={
            location.pathname.split("/")[1] === "bg-image"
              ? "activelink"
              : "link"
          }
        >
          <CiImageOn size={20} />
         BG Images
        </Link>
        <Link
          to={"/videos"}
          className={
            location.pathname.split("/")[1] === "videos"
              ? "activelink"
              : "link"
          }
        >
          <CiPlay1 size={20} />
         Videos
        </Link>
    </VStack>
  );
};

export default Sidebar;
