import { Card, Center, Container, Flex, useMediaQuery } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import HeadingText from "../../components/HeadingText";
import CustomInput from "../../components/CustomInput";
import SelectImage from "../../components/SelectImage";
import CustomButton from "../../components/CustomButton";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { addUpdateClaim, add_background_image, video_image } from "../../utils/apis";
import { BiArrowBack } from "react-icons/bi";
import { ID, isValidEmail } from "../../utils/utils";
import store from "../../redux/store";
import { claim_action } from "../../redux/slice/claimSlice";

const AddVideo = () => {
  const [isLarger] = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Obj, setObj] = useState({});
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);

  const { state } = useLocation();


  const validation = () => {
    if (!Obj?.title || !Obj.file) {
      setObj({
        ...Obj,
        titleval: !Obj?.title ? true : false,
        titlemess: !Obj?.title ? "Title is required" : "",

        fileval: !Obj?.file?.name ? true : false,
        filemess: !Obj?.file?.name ? "Background Image is required" : "",
      });
      return;
    } else {
      add_claim();
    }
  };

  // upload_video (video)
    const add_claim = () => {
    const body = new FormData();
    body.append("action", "upload_video");
    body.append("title", Obj.title);
    body.append("video", Obj.file);
    video_image(body, setLoading, state).then(
      (v) => v.success && navigate(-1)
    );
  };
  // const checkKeyPress = useCallback(
  //   (e) => {
  //     const { key, keyCode } = e;
  //     if (keyCode === 13) {
  //       validation();
  //     }
  //   },
  //   [claim_data]
  // );

  // useEffect(() => {
  //   window.addEventListener("keydown", checkKeyPress);
  //   return () => {
  //     window.removeEventListener("keydown", checkKeyPress);
  //   };
  // }, [checkKeyPress]);
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <HeadingText title={"Add Video"} />
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Card bg={"#fff"} mt={2} p={5}>
        <Flex gap={3} flexDirection={isLarger ? "row" : "column"}>
          <CustomInput
            label1={"Title"}
            placeholder1={"Enter Title"}
            value1={Obj.title}
            onChange1={(e) =>
              setObj({
                ...Obj,
                title: e.target.value,
                titleval: false,
                titlemess: "",
              })
            }
            onlyone
            errorBorder1={Obj.titleval}
            error1={Obj.titlemess}
          />
          <SelectImage
            mt={2}
            label={"Video* (Resolution : 1200x600)"}
            filename={Obj.file?.name}
            onChange={(e) =>
              setObj({
                ...Obj,
                file: e.target.files[0],
                fileval: false,
                filemess: "",
              })
            }
            // doctype={"image/png, image/jpg, image/jpeg "}
            errorBorder={Obj.fileval}
            doctype={"video/mp4, video/avi, video/mkv, video/mov, video/webm, video/ogg"}
            error={Obj.filemess}
          />
        </Flex>
        <Center mt={3}>
          <CustomButton
            title={"Submit"}
            loading={loading}
            onClick={() => validation()}
            // onClick={() => add_claim()}
          />
        </Center>
      </Card>
    </Container>
  );
};

export default AddVideo;
