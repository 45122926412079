import { configureStore } from "@reduxjs/toolkit";
import claimSlice from "./slice/claimSlice";
import otherSlice from "./slice/otherSlice";
import usersSlice from "./slice/usersSlice";
import messageSlice from "./slice/messageSlice";
import notificationSlice from "./slice/notificationSlice";
import providerSlice from "./slice/providerSlice";
import policylistSlice from "./slice/policylistSlice";
import employeeSlice from "./slice/employeeSlice";
import dashboardSlice from "./slice/dashboardSlice";
import documentSlice from "./slice/documentSlice";
import backgroundSlice from "./slice/backgroundSlice";
import videoSlice from "./slice/VideoSlice";

const store = configureStore({
  reducer: {
    claims: claimSlice,
    other: otherSlice,
    users: usersSlice,
    messages: messageSlice,
    notifications: notificationSlice,
    providers: providerSlice,
    policies: policylistSlice,
    employee: employeeSlice,
    dashboard: dashboardSlice,
    documents: documentSlice,
    background: backgroundSlice,
    video: videoSlice,
  },
});
export default store;
